<template>
    <div>
        <b-row v-if="item" class="m-0 p-0" align-v="center">
            <b-col sm="12" md="6" class="m-0 p-2">
                <b-img :src="$baseUrl + item.img" class="w-100" rounded />
            </b-col>
            <b-col sm="12" md="6" class="m-0 p-2">
                <h3 v-html="item.title[lang]"></h3>
                <p v-html="item.body[lang]"></p>
            </b-col>
        </b-row>


    </div>
</template>

<script>
export default {
    data() {
        return {
            item: null,
        }
    },
    mounted() {
        // this.lang = this.$route.query.lang
        this.item = this.data.section_3.items[this.$route.query.index]
    },
    computed: {
        data: {
            get: function () {
                return this.$store.state.data
            },
        },
        lang: {
            get: function () {
                return this.$store.state.lang
            },

            // set: function (newVal) {
            //     this.$store.state.lang = newVal
            // }
        }
    },
}
</script>

<style lang="scss" scoped></style>